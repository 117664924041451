<template>
  <v-card>
    <v-card-title>
      <FileUploadModal
        activator-name="Import produktów"
        @uploadedFile="upload"
        title="Import produktów"
        :pending="uploading"
        @dataImported="fetchItems"
        formats=".csv"
        ref="modal"
      />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        class="shrink"
        single-line
        hide-details
        @keyup.enter="fetchItems"
        @blur="fetchItems"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:[`item.lastSale`]="{item}">
        {{ item.lastSale | date }}
      </template>
      <template v-slot:[`item.active`]="{item}">
        {{ item.active ? 'Aktywny' : 'Nieaktywny' }}
      </template>
      <template v-slot:[`item.category`]="{item}">
        {{ item.categoryName }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RepositoryFactory from '@/repositories/RepositoryFactory'
import {mapState, mapActions} from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import FileUploadModal from '@/components/Modals/FileUploadModal'

const ProductRepository = RepositoryFactory.get('products')

export default {
  mixins: [paginationMixin],
  components: {
    FileUploadModal,
  },
  data() {
    return {
      search: '',
      loading: false,
      uploading: false,
      headers: [
        {text: 'Nazwa produktów', value: 'name'},
        {text: 'SKU', value: 'sku'},
        {text: 'Nazwa kategorii', value: 'category'},
        {text: 'idCeneo', value: 'id_Ceneo'},
        {text: 'Ost. sprzedaż', value: 'lastSale'},
        {text: 'Status', value: 'active'},
      ],
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['fetchAllProducts', 'showNotification']),
    async upload(formData) {
      try {
        this.uploading = true
        await ProductRepository.importProducts(formData)
        this.uploading = false
        this.$swal({
          title: 'Produkty zostały zaimportowane',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        })
        this.$refs.modal.close()
      } catch (e) {
        this.showNotification({
          message: e.response.data,
          type: 'error',
        })
        this.uploading = false
      }
    },
    async fetchItems() {
      const {search} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const query = this.$_paginationMixin_createQuery({
        ...this.$data.$_paginationMixin_options,
        search,
      })
      this.$data.$_paginationMixin_totalCount = await this.fetchAllProducts({
        ...query,
      })
      this.$data.$_paginationMixin_fetchingItems = false
    },
  },
  computed: {
    ...mapState({
      items: state => state.products.allProducts,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
      }
    },
  },
}
</script>

<style></style>
