var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('FileUploadModal',{ref:"modal",attrs:{"activator-name":"Import produktów","title":"Import produktów","pending":_vm.uploading,"formats":".csv"},on:{"uploadedFile":_vm.upload,"dataImported":_vm.fetchItems}}),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchItems($event)},"blur":_vm.fetchItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.$data.$_paginationMixin_options,"item-key":"id","loading":_vm.$data.$_paginationMixin_fetchingItems,"search":_vm.search,"items-per-page":_vm.$data.$_paginationMixin_itemsPerPage,"page":1,"server-items-length":_vm.$data.$_paginationMixin_totalCount,"footer-props":{
      'items-per-page-options': _vm.$data.$_paginationMixin_rowsPerPageOptions,
    }},on:{"update:options":function($event){return _vm.$set(_vm.$data, "$_paginationMixin_options", $event)}},scopedSlots:_vm._u([{key:"item.lastSale",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.lastSale))+" ")]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Aktywny' : 'Nieaktywny')+" ")]}},{key:"item.category",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.categoryName)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }